//import gsap, { Power4 } from 'imports/gsap'
import Cookies from 'imports/js-cookie'
let newSitePopup = {

    init:function () {
        // const newSitePopupClosed = Cookies.get('newSitePopupClosed')
        // if ( newSitePopupClosed !== '1') {
        //     $('#new-site-popup .close-button').on('click', newSitePopup.doClose)
        //     $('#new-site-popup .btn').on('click', newSitePopup.doButtonClick)
        //     $('#new-site-popup').show()
        // }
        // newSitePopup.cookieSet()
    }, //init

    cookieSet() {
        Cookies.set('newSitePopupClosed', '1')
    },

    doButtonClick() {
        newSitePopup.cookieSet()
        setTimeout(function() {
            window.location = '/register'
        },50)
    },

    doClose: function() {
        $('#new-site-popup').hide()
        newSitePopup.cookieSet()
    }
}
export default newSitePopup;
