import ScrollReveal from 'imports/scrollreveal'
import gsap, { Power3 } from 'imports/gsap'
let reveals = {
    init:function () {
        $('.sr-starting-visibility').css({"visibility":"visible"})
        $('.sr-fade').css({"visibility":"visible"})
        const dur = 1000
        const offset = 100
        ScrollReveal().reveal('.sr-fade', {
            distance: '0px',
            opacity: 0,
            duration: dur,
            viewOffset: {
                bottom: offset
            }
        });
        ScrollReveal().reveal('.sr-fade-delay1', {
            distance: '0px',
            opacity: 0,
            duration: dur,
            delay:150,
            viewOffset: {
                bottom: offset
            }
        });

        ScrollReveal().reveal('.sr-box-br', {
            viewOffset: {
                bottom: 200
            },
            beforeReveal: reveals.boxAnimationBR
        });
        ScrollReveal().reveal('.sr-box-tl', {
            viewOffset: {
                bottom: 200
            },
            beforeReveal: reveals.boxAnimationTL
        });
        ScrollReveal().reveal('.sr-box-bl', {
            viewOffset: {
                bottom: 200
            },
            beforeReveal: reveals.boxAnimationBL
        });


    },//init
    boxAnimationBR(t) {
        reveals.boxAnimation(t,-50,-50)
    },
    boxAnimationTL(t) {
        reveals.boxAnimation(t,50,50)
    },
    boxAnimationBL(t) {
        reveals.boxAnimation(t,50,-50)
    },
    boxAnimation(t, sx, sy) {
        let bg = $('.scrim-square',t)
        let fg = $('.square',t)
        gsap.fromTo(fg,
            {
                opacity: 0
            },
            {
                opacity: 1,
                duration: 0.5,
                ease:Power3.easeInOut
            }
        )
        gsap.fromTo(bg,
            {
                opacity: 0,
                x:sx,
                y:sy,
            },
            {
                opacity: 1,
                x:0,
                y:0,
                duration: 0.5,
                delay:0.25,
                ease:Power3.easeOut
            }
        )
        $('.sr-box').css({"visibility":"visible"})


    }

}//obj
export default reveals