let expandables = {
    init:function () {
        $(".expandables-hook .item .title").on('click',expandables.expandablesToggle)
        $(".expandables-hook .nested-item .nested-title").on('click',expandables.expandablesToggle)

        $(".expandables-hook .item .expandables-hide-text").on('click',expandables.expandablesHideBottomHook)

        $(".expandables-hook .item .title").attr('aria-expanded','false')
        $(".expandables-hook .nested-item .nested-title").attr('aria-expanded','false')
        $(".expandables-hook .item .expandables-hide-text").attr('aria-expanded','true')

        if(window.location.hash) {
            let tid = window.location.hash
            let scrollTo = $(tid).first().offset().top - 200
            setTimeout(function() {
                $('html,body').animate({
                    scrollTop: scrollTo
                }, 0)
            },500)
            $(tid).removeClass("locked")
            let sel = $(tid +'.item')
            expandables.openExpandablesHook(sel)
        }
    },//init

    expandablesToggle:function() {
        let elem = $(this).parent().parent()
        if ($(elem).hasClass('open')) {
            expandables.closeExpandablesHook(elem)
            $(this).attr('aria-expanded','false')
        } else {
            expandables.openExpandablesHook(elem)
            $(this).attr('aria-expanded','true')
        }
    },
    openExpandablesHook(elem) {
        $(elem).addClass('open')
        let tid = $(elem).attr('id')
        if (tid) {
            history.replaceState(null, null, document.location.pathname + '#' + tid)
        } else {
            history.replaceState(null, null, document.location.pathname)
        }
    },
    closeExpandablesHook(elem) {
        $(elem).removeClass('open')
        history.replaceState(null, null, document.location.pathname)
    },
    expandablesHideBottomHook:function() {
        let p = $(this).parent().parent().parent()
        $(p).removeClass('open')
    },


}//obj
export default expandables