//DEFAULTS
import "imports/core-js/stable";
import "imports/regenerator-runtime/runtime";
import overlay from 'site/overlay'
import site from 'site/site'
import global from 'site/global'
import expandables from 'site/expandables'
import mobileNav from 'site/mobileNav'
import adi from 'site/adi'
import reveals from 'site/reveals'
import newSitePopup from 'site/new-site-popup'
//import siteAOS from 'site/site.aos'
$(document).ready(function() {
    reveals.init()
    mobileNav.init()
    site.init()
    global.init()
    overlay.init()
    expandables.init()
    adi.init()
    newSitePopup.init()
    setTimeout( function() {

    },100)
})
